var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('Breadcrumb')],1),_c('div',{staticClass:"content-container"},[_c('common-search',{attrs:{"fields":_vm.fields},on:{"search":_vm.onSearch}}),_c('a-button',{staticClass:"btn--initiate",attrs:{"type":"primary"},on:{"click":_vm.onInitiate}},[_vm._v(" 发起协议 ")]),_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"loading":_vm.loading,"pagination":_vm.pagination,"row-key":"contractNo","scroll":{ x: 900 }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"agreementOperationType",fn:function(text){return [(text === '三方合作协议签订')?_c('span',[_vm._v("协议签订")]):_vm._e(),(text === '三方合作协议解除')?_c('span',[_vm._v("协议解除")]):_vm._e()]}},{key:"createTime",fn:function(text, record){return [_vm._v(" "+_vm._s(record.contractCreateTime ? _vm.dtFormat(record.contractCreateTime) : "-")+" ")]}},{key:"contractStatus",fn:function(text){return [_vm._v(" "+_vm._s(text === "已创建" ? "-" : text)+" ")]}},{key:"action",fn:function(text, record){return [_c('a-space',[(record.auditStatus === '待发起')?_c('a',{on:{"click":function($event){return _vm.onUploadAgreement(record.contractNo)}}},[_vm._v(" 上传协议 ")]):_vm._e(),(
              record.auditStatus === '未通过' ||
                (record.auditStatus === '已通过' &&
                  (record.contractStatus === '已截止签署' ||
                    record.contractStatus === '已到期'))
            )?_c('a',{on:{"click":function($event){return _vm.onModify(record.contractNo)}}},[_vm._v(" 协议修改 ")]):_vm._e(),(
              record.auditStatus !== '待发起' &&
                record.auditStatus !== '未通过'
            )?_c('a',{on:{"click":function($event){return _vm.onDetail(record.contractNo)}}},[_vm._v(" 查看详情 ")]):_vm._e(),(
              record.auditStatus === '已通过' &&
                record.contractStatus === '待签署'
            )?_c('a',{on:{"click":function($event){return _vm.onCancel(record.contractNo)}}},[_vm._v(" 合同取消 ")]):_vm._e()])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }