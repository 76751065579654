<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <a-button type="primary" class="btn--initiate" @click="onInitiate">
        发起协议
      </a-button>
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :loading="loading"
        :pagination="pagination"
        row-key="contractNo"
        :scroll="{ x: 900 }"
        @change="onPage"
      >
        <template slot="agreementOperationType" slot-scope="text">
          <span v-if="text === '三方合作协议签订'">协议签订</span>
          <span v-if="text === '三方合作协议解除'">协议解除</span>
        </template>
        <template slot="createTime" slot-scope="text, record">
          {{
            record.contractCreateTime
              ? dtFormat(record.contractCreateTime)
              : "-"
          }}
        </template>
        <template slot="contractStatus" slot-scope="text">
          {{ text === "已创建" ? "-" : text }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a
              v-if="record.auditStatus === '待发起'"
              @click="onUploadAgreement(record.contractNo)"
            >
              上传协议
            </a>
            <a
              v-if="
                record.auditStatus === '未通过' ||
                  (record.auditStatus === '已通过' &&
                    (record.contractStatus === '已截止签署' ||
                      record.contractStatus === '已到期'))
              "
              @click="onModify(record.contractNo)"
            >
              协议修改
            </a>
            <a
              v-if="
                record.auditStatus !== '待发起' &&
                  record.auditStatus !== '未通过'
              "
              @click="onDetail(record.contractNo)"
            >
              查看详情
            </a>
            <a
              v-if="
                record.auditStatus === '已通过' &&
                  record.contractStatus === '待签署'
              "
              @click="onCancel(record.contractNo)"
            >
              合同取消
            </a>
          </a-space>
        </template>
      </a-table>
    </div>
  </a-layout-content>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { loadArea } from "@/services/DealerService";
import {
  cancelTripartiteAgreement,
  fetchTripartiteAgreementList
} from "@/services/AgreementService";
import { dtFormat } from "@/components/DateUtils";
import { pageDetailMixins } from "@/components/pageDetailMixin";

const tblColumns = [
  {
    title: "服务中心",
    dataIndex: "serviceCenterName",
    width: 160
  },
  {
    title: "经营地区",
    dataIndex: "executorFullDetailAreaName",
    width: 240
  },
  {
    title: "货栈",
    dataIndex: "warehouseName",
    width: 160
  },
  {
    title: "协议签订/解除",
    dataIndex: "agreementOperationType",
    scopedSlots: { customRender: "agreementOperationType" },
    width: 140
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: 120
  },
  {
    title: "审核状态",
    dataIndex: "auditStatus",
    width: 100
  },
  {
    title: "合同状态",
    dataIndex: "contractStatus",
    scopedSlots: { customRender: "contractStatus" },
    width: 100
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: 160,
    fixed: "right"
  }
];

export default {
  name: "TripartiteAgreementManage",
  components: {
    Breadcrumb
  },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "服务中心",
          key: "serviceCenterName",
          component: "j-input"
        },
        {
          label: "所在地区",
          key:
            "serviceCenterProvinceAreaCode,serviceCenterCityAreaCode,serviceCenterCountyAreaCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        },
        {
          label: "货栈",
          key: "warehouseName",
          component: "j-input"
        },
        {
          label: "签订/解除",
          key: "contractType",
          defaultValue: "ALL",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: "ALL"
            },
            {
              label: "协议签订",
              value: "TRIPARTITE_COOPERATION_AGREEMENT_SIGN"
            },
            {
              label: "协议解除",
              value: "TRIPARTITE_COOPERATION_AGREEMENT_TERMINATE"
            }
          ]
        },
        {
          label: "创建时间",
          key: "contractCreateStartTime,contractCreateEndTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "合同状态",
          key: "contractSignStatus",
          defaultValue: "",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待签署",
              value: "待签署"
            },
            {
              label: "已签署",
              value: "已签署"
            },
            {
              label: "已取消",
              value: "已取消"
            },
            {
              label: "已截止签署",
              value: "已截止签署"
            },
            {
              label: "已到期",
              value: "已到期"
            }
          ]
        }
      ],
      form: {},
      areaData: [],
      tblData: [],
      tblColumns,
      loading: false,
      pagination: {
        showQuickJumper: true
      },
      dtFormat
    };
  },
  activated() {
    this.loadList();
  },
  mounted() {
    this.loadAreaData();
    this.loadList();
  },
  methods: {
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.areaData;
    },
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        contractCreateStartTime: this.form.contractCreateStartTime
          ? this.form.contractCreateStartTime + " 00:00:00"
          : "",
        contractCreateEndTime: this.form.contractCreateEndTime
          ? this.form.contractCreateEndTime + " 23:59:59"
          : "",
        contractSignStatus: this.form.contractSignStatus
          ? this.form.contractSignStatus
          : "",
        contractType: this.form.contractType ? this.form.contractType : "",
        serviceCenterProvinceAreaCode: this.form.serviceCenterProvinceAreaCode
          ? this.form.serviceCenterProvinceAreaCode
          : "",
        serviceCenterCityAreaCode: this.form.serviceCenterCityAreaCode
          ? this.form.serviceCenterCityAreaCode
          : "",
        serviceCenterCountyAreaCode: this.form.serviceCenterCountyAreaCode
          ? this.form.serviceCenterCountyAreaCode
          : "",
        serviceCenterName: this.form.serviceCenterName
          ? this.form.serviceCenterName
          : "",
        warehouseName: this.form.warehouseName ? this.form.warehouseName : "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchTripartiteAgreementList(params)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.loading = false;
            const data = resp.data.data;
            this.tblData = data.records;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          } else {
            this.loading = false;
            this.$message.error(resp.data.errorMsg);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 发起协议
    onInitiate() {
      this.$router.push({ name: "InitiateAgreement" });
    },
    // 查看详情
    onDetail(contractNo) {
      this.$router.push({
        name: "TripartiteAgreementDetail",
        params: {
          contractNo: contractNo
        }
      });
    },
    // 修改协议
    onModify(contractNo) {
      this.$router.push({
        name: "ModifyTripartiteAgreement",
        params: { type: "modify", contractNo: contractNo }
      });
    },
    // 上传协议
    onUploadAgreement(contractNo) {
      this.$router.push({
        name: "UploadTripartiteAgreement",
        params: { type: "upload", contractNo: contractNo }
      });
    },
    // 合同取消
    onCancel(contractNo) {
      var that = this;
      this.$confirm({
        title: "协议取消确认",
        content: "该协议还未签署生效，您确认取消合同吗？",
        okText: "确定",
        okType: "info",
        cancelText: "取消",
        onOk() {
          cancelTripartiteAgreement(contractNo).then(resp => {
            if (resp.data.code === "SUCCESS") {
              that.$message.success("取消成功");
              that.loadList();
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 100vh;
  background-color: white;
  margin: 30px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 98px;
}

.btn--initiate {
  margin-bottom: 20px;
}
</style>
